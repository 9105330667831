<template>
  <div id="dashboard">
    <div class="center">
      <h2>Welcome to Look4Trucks System</h2>
      <p>
        You can query <a href="/console/quotes">Quotes</a> or view
        <a href="/console/customs">Customs Brokerage Service</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
