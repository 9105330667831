<template>
  <div id="console">
    <div class="auth" v-if="!authed">
      <a href="/" class="logo"><img src="@/assets/logo.png" alt="logo" /></a>
      <div class="auth-form" v-if="step == 'signin'">
        <center class="notice">{{ notice }}</center>
        <el-form>
          <el-form-item label="Email">
            <el-input
              v-model="user.email"
              type="email"
              placeholder="Email"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="Password">
            <el-input
              v-model="user.password"
              type="password"
              placeholder="Enter your password"
              autocomplete="off"
              clearable
            >
            </el-input>
          </el-form-item>
          <div class="flex-space align-center">
            <el-button type="primary" @click="login">SIGN IN</el-button>
            <div>
              <el-button type="text" @click="toSignup">Register</el-button>
              <el-button type="text" @click="forgot">Forgot Password</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <div class="sign-up" v-else-if="step == 'signup'">
        <el-form>
          <el-form-item label="Company" :error="user.errors.company">
            <el-input placeholder="Company" v-model="user.company" />
          </el-form-item>
          <el-form-item label="Name" :error="user.errors.name">
            <el-input placeholder="Name" v-model="user.name" />
          </el-form-item>
          <el-form-item label="Email" :error="user.errors.email">
            <el-input
              placeholder="Email"
              type="email"
              v-model="user.email"
            ></el-input>
          </el-form-item>
          <el-form-item label="Password" :error="user.errors.password">
            <el-input
              v-model="user.password"
              placeholder="Enter your password"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>
          <el-form-item label=" ">
            <div class="recaptcha" />
          </el-form-item>
          <div class="flex-space align-center">
            <el-button type="primary" @click="signup" :disabled="!canSignup"
              >SIGN UP</el-button
            >
            <div>
              <el-button type="text" @click="toLogin">Back to Login</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <slot v-if="authed">
      <el-row>
        <el-col :span="22">
          <el-menu
            :default-active="activeIndex"
            :background-color="styles.bgColor"
            :text-color="styles.color"
            :active-text-color="styles.activeColor"
            :router="true"
            mode="horizontal"
          >
            <el-menu-item index="/console">Look4Trucks</el-menu-item>
            <el-menu-item index="/console/quotes">Quotes</el-menu-item>
            <el-menu-item index="/console/customs">Customs</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="2">
          <el-menu
            @select="profileSelect"
            :background-color="styles.bgColor"
            :text-color="styles.color"
            :active-text-color="styles.activeColor"
            class="top-right-menu"
            mode="horizontal"
          >
            <el-submenu index="#">
              <template slot="title">{{ $store.user.name }}</template>
              <el-menu-item index="signOut">SIGN OUT</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
      <div class="main">
        <CustomerHome v-if="isRoot" />
        <router-view></router-view>
      </div>
    </slot>
  </div>
</template>
<script>
import CustomerHome from "@/views/customer/Home.vue";
export default {
  name: "Console",
  components: {
    CustomerHome,
  },
  data() {
    return {
      styles: {
        bgColor: "#EE7700",
        color: "#F3F3F3",
        activeColor: "#FFFFFF",
      },
      user: { errors: {} },
      notice: null,
      step: "signin",
      recaptchaToken: null,
    };
  },
  async mounted() {
    try {
      if (this.lastActivityExpired()) {
        this.signOut();
        throw new Error("Last Activity Expired");
      } else {
        await this.currentUser();
      }
    } catch (err) {}
  },
  computed: {
    authed() {
      return this.$store.authed;
    },
    activeIndex() {
      return this.$route.path;
    },
    recaptchaKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    },
    isRoot() {
      return this.activeIndex == "/console";
    },
    canSignup() {
      return this.recaptchaToken;
    },
  },
  watch: {
    authed: {
      immediate: true,
      handler: async function (newVal) {
        if (!newVal && this.$route.name !== "console") {
          this.$router.replace({ name: "console" });
        }
      },
    },
  },
  methods: {
    initRecaptcha() {
      grecaptcha.ready(() => {
        this.recaptchaToken = null;
        grecaptcha.render(document.querySelector(".recaptcha"), {
          sitekey: this.recaptchaKey,
          callback: (token) => {
            this.recaptchaToken = token;
          },
          "expired-callback": () => {
            this.resetRecaptcha();
          },
        });
      });
    },
    resetRecaptcha() {
      grecaptcha.reset();
      this.recaptchaToken = null;
    },
    profileSelect(index) {
      switch (index) {
        case "signOut":
          this.signOut();
          break;
      }
    },
    toLogin() {
      this.step = "signin";
      delete this.user.password;
      delete this.user.email;
      this.user.errors = {};
      // Object.assign(this.user, { password: null, errors: {} });
    },
    toSignup() {
      this.step = "signup";
      this.initRecaptcha();
      delete this.user.password;
      delete this.user.email;
      this.user.errors = {};
      // Object.assign(this.user, { password: null, errors: {} });
    },
    async login() {
      try {
        this.signOut();
        const { email, password } = this.user;
        const response = await this.$http.post("/login", {
          user: { email, password },
        });
        if (response.status == 200) {
          this.setJwtToken(response.data.token);
          await this.currentUser();
          this.notice = null;
        } else {
          this.notice = response.data.error;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async signup() {
      try {
        const { company, name, email, password } = this.user;
        const response = await this.$http.post("/register", {
          user: { company, name, email, password },
          "g-recaptcha-response": this.recaptchaToken,
        });
        if (response.status == 201) {
          this.toLogin();
          this.notice = "Check your email to confirm your account";
        } else {
          this.user.errors = response.data.errors;
          this.resetRecaptcha();
        }
      } catch (error) {
        this.resetRecaptcha();
        console.log(error);
      }
    },
    async forgot() {
      try {
        if (this.isEmpty(this.user.email)) {
          this.notice = "Please enter the correct email first";
        } else {
          const response = await this.$http.post("/api/v1/passwords", {
            email: this.user.email,
            url: document.location.origin,
          });
          if (response.status == 200) {
            this.notice = response.data.message;
          } else {
            this.notice = response.data.errors;
          }
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss">
:root {
  --color-primary: #{$primary-color};
  --amazonOrange: #{$primary-color};
  --lightAmazonOrange: #{$light-primary-color};
  --darkAmazonOrange: #{$dark-primary-color};
}
.el-menu {
  .el-menu-item.is-active {
    border: none !important;
    background-color: $dark-primary-color !important;
  }
  .el-submenu__title i {
    color: #f3f3f3;
  }
}
#console {
  height: 100vh;
  .auth {
    height: 100vh;
    background: #1f2828;
    padding-top: 100px;
    a.logo {
      max-width: 400px;
      margin: auto;
      display: block;
      img {
        width: 100%;
      }
    }
    .auth-form,
    .sign-up {
      padding: 30px;
      width: 400px;
      margin: auto;
      background: #fff;
      border-radius: 10px;
      label.el-form-item__label {
        line-height: 24px !important;
      }
      .el-form-item {
        margin-bottom: 15dspx;
      }
    }
    .notice {
      color: red !important;
      line-height: 1.2;
      font-size: 14px;
    }
  }
  .main {
    height: calc(100% - 61px);
    padding: 5px;
    box-sizing: border-box;
  }
  .top-right-menu {
    .el-submenu {
      float: right !important;
      .el-submenu__title {
        text-transform: capitalize;
      }
    }
  }
}
</style>
